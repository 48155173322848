<template>
  <div class="verify-widget" :style="cardStyle" v-if="$user && !$isVerified">
    <div class="verify-card" :style="contentStyle">
      <figure :style="avatarStyle"><iconic :style="iconStyle" name="mf_check" /></figure>
      <div class="title" :style="titleStyle">
        <p :style="title1">{{ $locale["verify_identity"] }}</p>
        <p :style="desc1">{{ $locale["verify_identity_title"] }}</p>
        <p :style="desc1">{{ $locale["verify_identity_advatage_1_desc"] }}</p>
        <div>
          <router-link :style="btnStyle" class="primaryButton __pay" to="/user/verify">
            <span>{{ $locale["verifime_now"] }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      cardStyle: {},
      contentStyle: {
        position: "relative",
        padding: "var(--mpadding)",
        margin: "calc(var(--mpadding)) 0 0 0",
        "box-shadow": "var(--dshadod)",
        "border-radius": "var(--mpadding)",
        "background-color": "#bd0909",
        "background-image": "url(https://myfans1a.s3.amazonaws.com/miscellaneous/gifts_bg_tn.png)",
        "background-position": "center",
        "background-size": "cover",
        "min-height": "calc(var(--mpadding) * 4.5)",
        display: "flex",
        gap: "var(--mpadding)",
        color: "#fff",
      },
      iconStyle: {
        "font-size": "calc(var(--mpadding) * 4)",
        position: "absolute",
        top: "calc(var(--mpadding) / 4)",
        left: "calc(var(--mpadding) / 2)",
        height: "calc(var(--mpadding) * 4)",
        width: "calc(var(--mpadding) * 4)",
        overflow: "hidden",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        color: "#fff",
      },
      avatarStyle: {},
      titleStyle: {
        margin: "0 0 0 calc(var(--mpadding) * 3)",
      },
      title1: {
        "font-size": "150%",
        padding: "0 0 calc(var(--mpadding) /2) 0",
        "border-bottom": "1px solid #ffffff70",
      },
      desc1: {
        padding: "calc(var(--mpadding) /2) 0 0 0",
      },
      btnStyle: {
        margin: "calc(var(--mpadding) * 1) 0 0 0",
      },
    };
  },
};
</script>
